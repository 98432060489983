import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '@/views/home/HomePage.vue'
import EducationScheme from '@/views/education/EducationScheme.vue'
import PlayLearn from '@/views/playLearn/PlayLearn.vue'
import ShopList from '@/views/shopList/ShopList.vue'
import DownloadCenter from '@/views/downloadCenter/DownloadCenter.vue'
import HelpCenter from '@/views/helpCenter/HelpCenter.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/educationScheme',
    name: 'educationScheme',
    component: EducationScheme
  },
  {
    path: '/playLearn',
    name: 'playLearn',
    component: PlayLearn
  },
  {
    path: '/shopList',
    name: 'shopList',
    component: ShopList
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: HelpCenter
  },
  {
    path: '/downloadCenter',
    name: 'downloadCenter',
    component: DownloadCenter
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
    // name: '404',
    // component: 404
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== "playLearn") {
    window.scrollTo(0,0)
  }
  next()
})

export default router
