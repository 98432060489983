import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import message from 'ant-design-vue/es/message'

interface AxiosResponseType extends AxiosResponse {
  data:{
    msg: string,
    result: []
  }
}


const instance = axios.create({
  baseURL: '/api/v1'
})

const errorHandler = (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 500) {
      message.error('服务器异常，请稍后再试')
    }
  }
  return Promise.reject(error)
}

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (!navigator.onLine) {
    message.error('服务器异常，请稍后再试')
    return
  }
  return config
}, errorHandler)

instance.interceptors.response.use((response: AxiosResponseType) => {
  return response
}, errorHandler)



export default instance
