import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './global.less';
// declare global {
//   interface Window {
//     TCPlayer: any
//   }
// }
createApp(App).use(Antd).use(store).use(router).mount('#app');
