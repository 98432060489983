import request from "../utils/request";

export const queryCourses = (params:object) => {
  return request({
    url: '/dotpack/get_play_learn',
    method: 'get',
    params: {
      ...params,
      page: 1, 
      page_size: 999
    }
  })
}
export const validateBag = (params:any) => {
  console.log('params',params);
  return request({
    url: `/dotpack/validate_bag?mac_address=${params.mac_address}`,
    method: 'get',
  })
}