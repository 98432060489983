export type programmeType = {
  id: string
  background: string
  img: string
  title: string
  description: string
}

export type coursesType = {
  cover_link: string
  description: string
  download_url: string
  id: number
  sort_num: number
  subtitle: string
  title: string
}

export type dataItemType = {
  id: number
  name: string
  sort_num: number
  description: string
  cover_link: string
  cover_link_h5: string
  supplement_four: string
  supplement_one: string
  supplement_three: string
  supplement_two: string
  courses: coursesType[]
  title: string
  activeKey: number
  href: string
  moreBtn: boolean
}

export type stateType = {
  bnanerObj: programmeType
  dataList: dataItemType[],
  spinning: boolean,
  detailsFlag: string,
  activeIndex: number,
  macVerification:boolean,
  showModal:boolean,
  curDownloadUrl:string
}

export const bnanerArrData: programmeType[] = [
  {
    id: '0',
    background: '#0084ff',
    img: 'banner_1.png',
    title: '编程',
    description: '编程启蒙，从这里开始'
  },
  {
    id: '1',
    background: '#ffd55c',
    img: 'banner_2.png',
    title: '探索',
    description: '探索编程书包的神奇世界'
  }
]