import request from "../utils/request";
type FormData = {
  name: string;
  org_name: string;
  org_type: string;
  other_type: string;
  new_student_age: string;
  other_age: string;
  phone: string;
  verification: string;
};

// 发送验证码
export const sendVerificationCode = (phone: string) => {
  return request({
    url: "/user_courseware_record",
    method: "post",
    data: {
      phone,
    },
  });
};

// 验证验证码
export const checkVerificationCode = (params: {
  phone: string;
  code: string;
}) => {
  return request({
    url: "/user_courseware_record",
    method: "get",
    params,
  });
};

// 提交表单
export const querySubmitForm = (data: FormData) => {
  return request({
    url: "/create_user_courseware_info",
    method: "post",

    data,
    transformRequest: [
    ],
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
