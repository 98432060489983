import request from "../utils/request";

export type DetailType = {
    id: string
    title: string
    title_link_url: string
    subtitle: string
    subtitle_link_url: string
    type_label: string
    sort_num: string
    description: string
    cover_link: string
    cover_link_url: string
    is_display: string
    created_at: string
}
export type HelpItemType = {
  id: number
  list?: DetailType[]
  title_link_url: string
  type_label: string
}


// 获取列表
export const getHelpList = (api_name: string) => {
    return request({
      url: `/dotpack/help/?api_name=${api_name}`,
      method: "get",
    });
  };
